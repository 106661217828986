import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import axios from 'axios';
import { keys } from '../keys';
import { link } from '../base_url';
import {useParams} from "react-router-dom"
import './addTestNew.css';

const AddTestNew = () => {
  var {level_id,test_id,lang}=useParams();
  // const [level, setLevel] = useState(6); // Level is pre-selected
  const [topics, setTopics] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [problemCounts, setProblemCounts] = useState({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const loadTopics = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${link.url}/api/level/topics?level_id=${level_id}`,
        {
          headers: {
            'authorization': keys.authorization,
          }
        }
      );
      console.log(response.data)
      
      setTopics(response.data.topicList || []);
    } catch (err) {
      console.error('Failed to load topics:', err);
    }
    setLoading(false);
  };

  const handleTopicSelect = (topic) => {
    if (selectedTopics.find(t => t.topic_id === topic.topic_id)) {
      setSelectedTopics(selectedTopics.filter(t => t.topic_id !== topic.topic_id));
      // Remove problem count when topic is deselected
      const newCounts = { ...problemCounts };
      delete newCounts[topic.topic_id];
      setProblemCounts(newCounts);
    } else {
      setSelectedTopics([...selectedTopics, topic]);
    }
  };

  const handleProblemCountChange = (topicId, count) => {
    setProblemCounts({
      ...problemCounts,
      [topicId]: parseInt(count) || 0
    });
  };

  const handleSubmit = async () => {
    setSaving(true);
    try {
      // Transform data for API
      const distributions = selectedTopics.map(topic => ({
        topic_id: topic.topic_id,
        problem_count: problemCounts[topic.topic_id] || 0
      }));
console.log(distributions)
      // Call your API here
      // await axios.post(`${link.url}/api/tests/create`, {
      //   level_id: level,
      //   distributions
      // });

      // console.log('Test configuration:', { level, distributions });
    } catch (err) {
      console.error('Failed to save test:', err);
    }
    setSaving(false);
  };

  return (
    <div className="add-test-container">
      <Navbar />
      
      <div className="test-setup-content">
        <div className="setup-header">
          <h2>Create New Test</h2>
        </div>

        <div className="fetch-topics-section">
          <h3>1. Fetch Topics</h3>
          <button
            className="rainbow-btn"
            onClick={loadTopics}
            disabled={loading}
          >
            {loading ? (
              <><i className="fas fa-spinner fa-spin"></i> Fetching...</>
            ) : (
              <>
                <i className="fas fa-sync-alt"></i>
                Load Available Topics
              </>
            )}
          </button>
        </div>

        {topics.length > 0 && (
          <div className="topics-section">
            <h3>2. Select Topics and Set Problem Count</h3>
            <div className="topics-grid">
              {topics.map(topic => (
                <div 
                  key={topic.topic_id}
                  className={`topic-card ${
                    selectedTopics.find(t => t.topic_id === topic.topic_id) ? 'selected' : ''
                  }`}
                >
                  <div className="topic-header" onClick={() => handleTopicSelect(topic)}>
                    <img src={topic.logo} alt={topic.name} className="topic-icon" />
                    <h4>{topic.name}</h4>
                    <span className="problem-count">
                      {topic.nproblem || 0} problems available
                    </span>
                  </div>
                  
                  {selectedTopics.find(t => t.topic_id === topic.topic_id) && (
                    <div className="problem-count-input">
                      <div className="count-controls">
                        <button 
                          className="count-btn"
                          onClick={() => handleProblemCountChange(
                            topic.topic_id, 
                            (problemCounts[topic.topic_id] || 0) - 1
                          )}
                          disabled={(problemCounts[topic.topic_id] || 0) <= 0}
                        >
                          <i className="fas fa-minus"></i>
                        </button>
                        <input
                          type="number"
                          min="0"
                          max={topic.nproblem}
                          value={problemCounts[topic.topic_id] || ''}
                          onChange={(e) => handleProblemCountChange(topic.topic_id, e.target.value)}
                          placeholder="0"
                        />
                        <button 
                          className="count-btn"
                          onClick={() => handleProblemCountChange(
                            topic.topic_id, 
                            (problemCounts[topic.topic_id] || 0) + 1
                          )}
                          disabled={(problemCounts[topic.topic_id] || 0) >= topic.nproblem}
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                      <div className="count-progress">
                        <div 
                          className="progress-bar" 
                          style={{
                            width: `${((problemCounts[topic.topic_id] || 0) / topic.nproblem) * 100}%`
                          }}
                        ></div>
                        <span className="progress-text">
                          {problemCounts[topic.topic_id] || 0} / {topic.nproblem}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        {selectedTopics.length > 0 && (
          <div className="problem-count-section">
            <h3>3. Set Problem Counts</h3>
            <div className="selected-topics-grid">
              {selectedTopics.map(topic => (
                <div key={topic.topic_id} className="count-input-card">
                  <h4>{topic.name}</h4>
                  <div className="count-input-wrapper">
                    <input
                      type="number"
                      min="0"
                      max={topic.nproblem|| 0}
                      value={problemCounts[topic.topic_id] || ''}
                      onChange={(e) => handleProblemCountChange(topic.topic_id, e.target.value)}
                      placeholder="Enter count"
                    />
                    <span className="max-count">
                      Max: {topic.nproblem || 0}
                    </span>
                  </div>
                </div>
              ))}
            </div>

            <button
              className="submit-btn"
              onClick={handleSubmit}
              disabled={saving || !selectedTopics.every(t => problemCounts[t.topic_id] > 0)}
            >
              {saving ? (
                <><i className="fas fa-spinner fa-spin"></i> Saving...</>
              ) : (
                'Submit'
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddTestNew;
