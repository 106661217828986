import React, { Component } from "react"
import { useSelector } from "react-redux"
import firebase from "../firebase"
import { connect } from "react-redux"
import { fetchProblems } from "../actions/problemAction"
import { keys } from "../keys"
import axios from "axios"
import Problem from "./Edit/Problem"
import ProblemEdit from "./Edit/ProblemEdit"
import Navbar from "./Navbar"
import {link} from "../base_url"
import { Link } from "react-router-dom"
import './problems.css'

var c = 0;
var k=0;
class Allproblems extends Component {
  state = {

    structure: [],
    mainAra: [],
    category: {},
    f: [],
    confirmed: [],
    serial: "",
    series_id: "",
    currentProblem: null,
    count: 0,
    width: '',
    lang: "en",
    loading: 0,
    bnLoading: 0,
    allLoading:0,
UnMappedLoading:0,
    mapped_id: "",
    setters:[],
    level:"",
    diff:"",
    lastDocument:null,
    start:0,
    end:20,
    setter:"",
    curPage:1,
    totPages:1,
    waitingQueueLoading: false

  }

  componentDidMount=async ()=>{


    axios.get('https://dev.brainlytic.org/api/v2/setter/author?limit=1000',{

      headers:{
        'authorization':keys.authorization,
      
      }
      

    }).then(res=>{
console.log(res.data)
this.setState({

  setters:res.data.authors
})
    })


  }
 reShapeProblem = async (problems) => {
  

    c = 0;
    let p = [];
    let t = {};
    let tempData = {}
    let temp = {};
    let dataTemp = {};
    let mainData = {}
 
var cc=0;

    problems&&problems.forEach(problem=> {
      
      console.log(problem)
      tempData = {}
              temp = {};
              t = {}
              dataTemp = {};
              mainData = {}
              if (problem.width)
                this.setState({ width: problem.width })

              let cat = problem?.category?.split(">")[1];
        if (cat) {
          
          if (!(this.state.category.hasOwnProperty(cat.toLowerCase())))
            this.state.category[cat.toLowerCase()] = Object.keys(this.state.category).length + 1;
        }
        else {
      
          cat = problem?.category?.toLowerCase();
          if (!(this.state.category.hasOwnProperty(problem?.category?.toLowerCase())))
            this.state.category[problem?.category?.toLowerCase()] = Object.keys(this.state.category).length + 1;

        }
        temp["version"] = 1;
        // if(problem.trophy){
        //   t["trophy"]=problem.trophy
        // }else{
        //   t["trophy"]= null
        // }

                if (problem?.is_pending) {
                  t["isPending"] = problem.is_pending;
                  t["is_pending"] = problem.is_pending;
                  c += 1;
        
                }
                else {
                  t["isPending"] = false;
                  t["is_pending"] = false;
        
        
                }
                t["author_mail"] = problem?.author_email;
                t["created_at"] = problem?.created_at;
                t["updated_at"] = problem?.updated_at;
                t['associated_problem_id']=problem?.associated_problem_id
                // t["author_id"] = problem?.author_email;
    
                t["setter_name"]=this.getName(problem?.author_email);
                        if (problem?.description) {
                          temp["description"] = problem.description;
                        }
                        temp["latex"]=problem.latex?problem.latex:"";
                        if (problem?.hint) {
                          temp["hint"] =problem.hint;
                        }
                        t["id"] = problem.id;
                        t["setter_problem_id"] = problem.id;
                                temp["category"] = cat;
                                t["difficulty"] = problem?.difficulty
                                temp["lang"] = problem?.language
                                t["title"] = problem?.title;
                                // temp["interactiveType"] = doc.data().interactiveType == 1 ? "none" : doc.data().interactiveType == 3 ? "dragAndDrop-2" : doc.data().interactiveType == 4 ? "dragAndDrop-1" : doc.data().interactiveType == 5 ? "rearrange" : doc.data().interactiveType == 6 ? "matchstick" : doc.data().interactiveType == 7 ? "venn" : doc.data().interactiveType == 8 ? "dragAndDrop-3-Grid" : "exclusion_grid";
                                temp["interactiveType"] =problem?.interactive_type;

                                t["timestp"] = new Date(problem.created_at).getTime();
                        
                                t["logo"] = problem.logo;
                                t["grade"] = problem.grade;
                                // temp["ansType"] = doc.data().ansType == 1 ? "mcq" : (doc.data().ansType == 2 ? "text" : "interactive");
                                temp["ansType"] = problem.answer_type;
                                if (problem.prob_id) {
                                  t["prob_id"] = problem.prob_id
                                }
                                if (problem.is_live == true || problem.is_live == false) {
                                  t["is_live"] = problem.is_live;
                        
                                }
                                if (problem.is_approved == true ||problem.is_approved  == false) {
                                            t["is_live"] = problem.is_approved ;
                                          }
                                          else {
                                            if (problem.is_live == true || problem.is_live == false) {
                                              t["is_live"] = problem.is_live;
                                            } else {
                                              t["is_live"] = false;
                                  
                                            }
                                    }

                                    if (problem.tags) {
                                                temp["tags"] = problem.tags;
                                              }
                                              if (problem.serial) {
                                                t["serial"] = parseInt(problem.serial)
                                              }
                                              if (problem.series_id) {
                                                t["series_id"] = parseInt(problem.series_id)
                                              }
                                              temp["type"] = temp.interactiveType;
                                              temp["statement"] = problem.statement;
                                              if (problem.restrictions) {
                                                temp["restriction"] = problem.restrictions;
                                              }
                                      
                                              temp["explanation"] = problem.explanation;
                                              dataTemp["type"] = temp.ansType;
     
//       docs.forEach(doc => {



//         this.setState({lastDocument:doc})
//         tempData = {}
//         temp = {};
//         t = {}
//         dataTemp = {};
//         mainData = {}
//         if (doc.data().width)
//           this.setState({ width: doc.data().width })
//         if (doc.data().interactiveType == 7) { }

//         let cat = doc.data().cat.split(">")[1];
//         if (cat) {
          
//           if (!(this.state.category.hasOwnProperty(cat.toLowerCase())))
//             this.state.category[cat.toLowerCase()] = Object.keys(this.state.category).length + 1;
//         }
//         else {
      
//           cat = doc.data().cat.toLowerCase();
//           if (!(this.state.category.hasOwnProperty(doc.data().cat.toLowerCase())))
//             this.state.category[doc.data().cat.toLowerCase()] = Object.keys(this.state.category).length + 1;

//         }
//         temp["version"] = 1;

//         if (doc.data().isPending) {
//           t["isPending"] = doc.data().isPending;
//           c += 1;

//         }
//         else {
//           t["isPending"] = false;


//         }

//         t["author_id"] = doc.data().uid;
    
//         t["setter_name"]=this.getName(doc.data().uid);
//         if (doc.data().description) {
//           temp["description"] = doc.data().description;
//         }
//         temp["latex"]=doc.data().latex?doc.data().latex:"";
//         if (doc.data().hint) {
//           temp["hint"] = doc.data().hint;
//         }
//         t["doc_id"] = doc.id;
//         temp["category"] = cat;
//         t["difficulty"] = doc.data().difficulty == 1 ? "easy" : (doc.data().difficulty == 2 ? "medium" : "hard");
//         temp["lang"] = doc.data().language == 1 ? "en" : "bn";
//         t["title"] = doc.data().title;
//         temp["interactiveType"] = doc.data().interactiveType == 1 ? "none" : doc.data().interactiveType == 3 ? "dragAndDrop-2" : doc.data().interactiveType == 4 ? "dragAndDrop-1" : doc.data().interactiveType == 5 ? "rearrange" : doc.data().interactiveType == 6 ? "matchstick" : doc.data().interactiveType == 7 ? "venn" : doc.data().interactiveType == 8 ? "dragAndDrop-3-Grid" : "exclusion_grid";
//         t["timestp"] = doc.data().timestamp;

//         t["logo"] = doc.data().logo;
//         t["grade"] = doc.data().grade;
//         temp["ansType"] = doc.data().ansType == 1 ? "mcq" : (doc.data().ansType == 2 ? "text" : "interactive");
//         if (doc.data().prob_id) {
//           t["prob_id"] = doc.data().prob_id
//         }
//         if (doc.data().isLive == true || doc.data().isLive == false) {
//           t["isLive"] = doc.data().isLive;

//         }
//         if (doc.data().isApproved == true || doc.data().isApproved == false) {
//           t["islive"] = doc.data().isApproved;
//         }
//         else {
//           if (doc.data().islive == true || doc.data().islive == false) {
//             t["islive"] = doc.data().islive;
//           } else {
//             t["islive"] = false;

//           }
//         }
//         if (doc.data().tags) {
//           temp["tags"] = doc.data().tags;
//         }
//         if (doc.data().serial) {
//           t["serial"] = doc.data().serial
//         }
//         if (doc.data().series_id) {
//           t["series_id"] = doc.data().series_id
//         }
//         temp["type"] = temp.interactiveType;
//         temp["statement"] = doc.data().statement;
//         if (doc.data().restrictions) {
//           temp["restriction"] = doc.data().restrictions;
//         }

//         temp["explanation"] = doc.data().explanation;
//         dataTemp["type"] = temp.ansType;
        if (dataTemp.type == "mcq" && temp.type == "none") {
          mainData["options"] = problem.options;
          mainData["answer"] =problem.answer?problem.options[problem.answer - 1]:'0';

        }
        else if (temp.type == "none" && dataTemp.type == "text") {
          mainData["answer"] = problem.answer;
        }
        else if (temp.type == "exclusion_grid") {
try{

          let ques = JSON.parse(problem.questionnaire);

          dataTemp["rowHeading"] = ques.rows;
          dataTemp["columnHeading"] = ques.cols;
          dataTemp["cell"] = ques.data;
          dataTemp["initialState"] = ques.state;
          if (dataTemp.type == "interactive") {
            let ans = JSON.parse(problem.answer);

            if (typeof ans === 'string') {
              ans = JSON.parse(ans); 
            }

            mainData["answer"] = ans.state;

          }

          else if (dataTemp.type == "mcq") {
            mainData["options"] = problem.options;
            mainData["answer"] = problem.answer?problem.options[problem.answer - 1]:'0';
          }
          else if (dataTemp.type == "text") {
            mainData["answer"] = problem.answer;
          }

          }
catch(e){

}
        }
        else if (temp.type === "dragAndDrop-2") {
          let ques=""
         
    
          if(problem.questionnaire)
           ques = JSON.parse(problem.questionnaire)
          if (dataTemp.type == "mcq") {
            mainData["options"] = problem.options;
            mainData["answer"] = problem.answer?problem.options[problem.answer - 1]:'0';

          }
          else if (dataTemp.type == "text") {
            mainData["answer"] = problem.answer;
          }
          temp["questionnaire"] = ques;

        }
        else if (temp.type == "dragAndDrop-1") {

          let ques = JSON.parse(problem.questionnaire);
          if (dataTemp.type == "interactive") {
            let ans = JSON.parse(problem.answer);
            if (typeof ans === 'string') {
              ans = JSON.parse(ans); 
            }
            mainData["temp"] = ans.items;
            let array = []
          ans.containers.forEach((e, idx) => {
              let obj = {};
              obj["label"] = e;
              obj["items"] = ans.schema[idx];
              array.push(obj)

            })

            mainData["answer"] = array;

          }
          else if (dataTemp.type == "mcq") {
            mainData["options"] = problem.options;
            mainData["answer"] = problem.answer?problem.options[problem.answer - 1]:'0';

          }
          else if (dataTemp.type == "text") {
            mainData["answer"] = problem.answer;
          }
          dataTemp["unselected"] = ques?.items;

          let array = []
          ques&&ques.containers.forEach((e, idx) => {
            let obj = {};
            obj["label"] = e;
            obj["items"] = ques.schema[idx];
            array.push(obj)

          })
          dataTemp["containers"] = array;

        }

        else if (temp.type === 'dragAndDrop-3-Grid') {


          let ques = JSON.parse(problem.questionnaire);

          if (dataTemp.type == "interactive") {
        
            let ans = JSON.parse(problem.answer);
            if (typeof ans === 'string') {
              ans = JSON.parse(ans); 
            }
           
            mainData["temp"] = ans.items;
            let array = []
            ans.containers.forEach((e, idx) => {
              let obj = {};
              obj["label"] = e;
              obj["items"] = ans.schema[idx];


              array.push(obj)

            })

            mainData["answer"] = array;

          }
          else if (dataTemp.type == "mcq") {
            mainData["options"] = problem.options;
            mainData["answer"] =problem.answer? problem.options[problem.answer - 1]:'0';

          }
          else if (dataTemp.type == "text") {
            mainData["answer"] = problem.answer;
          }
          dataTemp["unselected"] = ques?.items;

          let array = []
          ques&&ques.containers.forEach((e, idx) => {
            let obj = {};
            obj["label"] = e;
            obj["items"] = ques.schema[idx];
            array.push(obj)

          })
          dataTemp["active"] = ques?.active;
          dataTemp["nCols"] = ques?.nCols;
          dataTemp["nRows"] = ques?.nRows;

          dataTemp["containers"] = array;

        }
        else if (temp.type == "rearrange") {
          let ques = JSON.parse(problem.questionnaire);
          if (dataTemp.type == "interactive") {
    
            let ans = JSON.parse(problem.answer);
            if (typeof ans === 'string') {
              ans = JSON.parse(ans); 
            }
         
            mainData["answer"] = ans.items;

          }
          else if (dataTemp.type == "mcq") {
            mainData["options"] = problem.options;
            mainData["answer"] =  problem.answer?problem.options[problem.answer - 1]:'0';

          }
          else if (dataTemp.type == "text") {

            mainData["answer"] = problem.answer;
          }

          dataTemp["fields"] = ques.items;

        }
        else if (temp.type == "matchstick") {
          let ques = JSON.parse(problem.questionnaire);
          if (dataTemp.type == "interactive") {
            let ans = JSON.parse(problem.answer);
            if (typeof ans === 'string') {
              ans = JSON.parse(ans); 
            }
            
            mainData = ans;
          }
          else if (dataTemp.type == "mcq") {
            mainData["options"] = problem.options;
            mainData["answer"] =problem.answer?problem.options[problem.answer - 1]:'0';

          }
          else if (dataTemp.type == "text") {

            mainData["answer"] = problem.answer;
          }


          dataTemp = ques;
          dataTemp["type"] = temp.ansType;

        }
        else if (temp.type == "venn") {
          let ques = JSON.parse(problem.questionnaire);
          if (dataTemp.type == "interactive") {

            let ans = JSON.parse(problem.answer); 
            if (typeof ans === 'string') {
              ans = JSON.parse(ans); 
            }
            mainData["answer"] = ans;
          }
          else if (dataTemp.type == "mcq") {
            mainData["options"] = problem.options;
            mainData["answer"] = problem.answer?problem.options[problem.answer - 1]:'0';

          }
          else if (dataTemp.type == "text") {

            mainData["answer"] = problem.answer;
          }
          dataTemp = ques;
          dataTemp["type"] = temp.ansType;



        }


        dataTemp["data"] = mainData;
        temp["data"] = dataTemp;
        temp["width"] = this.state.width
        t["data"] = temp;
        t["is_live"] = problem.is_live !== null || problem.is_live !== undefined ? problem.is_live : true;
        t["is_premium"] = problem.is_premium !== null ? problem.is_premium : false;
        t["is_for_test"] = problem.is_for_test !== null ? problem.is_for_test : false;
   
        p.push(t);
      

      })
    // })
   console.log(p)
// return t;
    await this.setState({
      structure: p,
      f:p,
      // mainAra:p,
      // count: c,
    //  end:1000
    //  end:p.length==0?20:p.length
      
    })
  


  }
getName= (mail) =>{
  let name;
  this.state.setters.map(s=>{
    
  if(s.email==mail){
   
    name=s.name;
    
  }
 })
 return name;
}

  // Add this helper function before render()
  formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays <= 1) {
      // Show hours ago
      const hours = Math.ceil(diffTime / (1000 * 60 * 60));
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (diffDays <= 7) {
      // Show days ago
      return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
    } else {
      // Show formatted date
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    }
  }

  click = async (state,mail=null) => {
    

    await this.setState({ allLoading: 1 })
    // this.setState({
    //   f: this.state.structure,
    //   mainAra: this.state.structure
    // })

    // this.state.f.length>0 && this.state.f.slice(this.state.start,this.state.end).filter(e=>e.difficulty.includes(this.state.diff)).forEach(e => {

    //   document.getElementById(e.doc_id).style.display = "block"
    // },

    // )
    let url=""

    if(state==1){

      url='https://dev.brainlytic.org/api/v2/admin/problem?limit=100&page='+this.state.curPage
    }else{

url="https://dev.brainlytic.org/api/v2/setter/problem?authorEmail="+mail+"&limit=5000"

}

    axios.get(url,{

      headers:{
        'authorization':keys.authorization,
      
      }
      

    }).then(res=>{





      // Sort problems by updated_at in descending order
      const sortedProblems = res.data.problems.sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });

      this.reShapeProblem(sortedProblems);

      this.setState({
        // f: sortedProblems,
        mainAra: sortedProblems,
        structure: sortedProblems,
        allLoading: 0,
        totPages: res.data.totalPages
      });
    }).catch(e=>{
      this.setState({ allLoading: 0 }) 
    })
 
  }

  go=(prob)=>{

   let level=prob.level_id-5;
   console.log(prob)

let link=`https://staging.brainlytic.org/lang/en/level/${level}/series/${prob.series_id}/problem/${prob.serial}`

const dom=document.getElementById("prob_link");;
dom.href=link;
dom.click();
  }
  editMappedProblem=async (prob)=>{

    let id1=prob.prob_id?prob.prob_id:prob.problem_id;

let id2=parseInt(this.state.mapped_id);
let data={problem_id_1:id1,problem_id_2:id2};



axios({
  method: 'post',
  url: link.url+'/admin/associate/create',
  data: data,
  headers: {
    'authorization': keys.authorization,
  }
}).then(res=>{

  let temp= this.state.mainAra.find(problem => problem.id === prob.id);  

temp['associated_problem_id']=id2;

axios({
  method: 'put',
  url: link.url+'/api/v2/admin/problem/'+prob.id,
  data: temp,
  headers: {
    'authorization': keys.authorization,
  }
}).then(res=>{

  alert("Problem mapping successful");

})



}).catch(e=>console.log(e))


// let id1=prob.prob_id?prob.prob_id:prob.problem_id;

// let id2=parseInt(this.state.mapped_id);
// prob['associated_problem_id'] =id2;


// axios({
//   method: 'put',
//   url: link.url+'/api/v2/admin/problem/'+prob.id,
//   data: prob,
//   headers: {
//     'authorization': keys.authorization,
//   }
// }).then(res=>{
//   alert("Problem mapping successful");

//   // firebase.firestore().collection("problem").doc(prob.doc_id).update({
//   //   associated_problem_id:id2
//   // })



// }).catch(e=>{
//   console.log(e);
// })

  }

  bnclick = async () => {
    await this.setState({ bnLoading: 1 })
    axios.get('https://dev.brainlytic.org/api/v2/admin/problem?limit=5000', {
      headers: {
        'authorization': keys.authorization,
      }
    }).then(res => {
      const filteredProblems = res.data.problems.filter(problem => 
        problem.language=='bn'
      );
      this.setState({
        // f: filteredProblems,
        mainAra: filteredProblems,
        bnLoading: 0,
       
        // waitingQueueLoading: false,
        totPages:1
      });
      this.reShapeProblem(filteredProblems);
    }).catch(err => {
      this.setState({ bnLoading: 0});
    });

    // axios({
    //   method: 'get',
    //   url: link.url+'admin/getAllBanglaProblems',

    //   headers: {
    //     'authorization': keys.authorization,
    //   }
    // }).then(res => { this.setState({ bnLoading: 0 }); this.setState({ f: res.data }); })
    //   .catch(e => { this.setState({ bnLoading: 0 }); console.log(e) })



  }

  filterBydifficulty=(e)=>{
 if(e.target.value!='All'){
    const filteredProblems = this.state.mainAra.length>0&& this.state.mainAra.filter(problem => 
      problem.difficulty==e.target.value
    );

    this.reShapeProblem(filteredProblems);
     this.setState({

// f: filteredProblems ,
diff:e.target.value

    })
}
else{
   
 this.setState({

// f: this.state.mainAra ,
diff:e.target.value

    })
    this.reShapeProblem(this.state.mainAra);
    
  }
  }
 UnMappedClick = async () => {
    await this.setState({ UnMappedLoading: 1 })
   

//     axios({
//       method: 'get',
//       url: link.url+'admin/getAllProblems',

//       headers: {
//         'authorization': keys.authorization,
        
//       }
//     }).then(res => { this.setState({ UnMappedLoading: 0 } ); 
// console.log(res.data.length)
// var a=res.data.filter(function(item)
//  {


//   return item.associated_problem_id==null;
//  });

// a.sort(function(x, y){
//   return -x.timestp + y.timestp;
// })
// this.setState({ f: a });
//  })
//       .catch(e => { this.setState({ UnMappedLoading: 0 }); console.log(e) })

axios.get('https://dev.brainlytic.org/api/v2/admin/problem?limit=100&page='+this.state.page, {
  headers: {
    'authorization': keys.authorization,
  }
}).then(res => {
  const filteredProblems = res.data.problems.filter(problem => 
    problem.associated_problem_id ==null || problem.associated_problem_id ==undefined
  );
  this.setState({
   
    mainAra: filteredProblems,
    UnMappedLoading: 0,
   
    // waitingQueueLoading: false,
    totPages:res.data.totalPages
  });
  
  this.reShapeProblem(filteredProblems);
}).catch(err => {
  this.setState({ UnMappedLoading: 0 });
});

  }


  disapprove = (id) => {
    firebase.firestore().collection("problem").doc(id).update({
      isApproved: false
    })


  }
  setCurrentProbToNull = () => {
    this.setState({
      currentProblem: null
    })
  }


  delete = (id, prob) => {
   
    let data = {};
    data["problem_id"] = prob.prob_id;
    axios({
      method: 'post',
      url: link.url+'/admin/deleteProblem',
      data: data,
      headers: {
        'authorization': keys.authorization,
      }
    }).then(res => {
      let prob2 = this.state.mainAra.find(problem => problem.id === prob.id);  
      prob2["serial"] = null;

      prob2["series_id"] = null;
      prob2["is_live"] = false;
      prob2["is_approved"] = false;
      prob2["is_pending"] = true;
      prob2["problem_id"]=null;
      prob2["prob_id"]=null
      prob2['is_premium']=false;

      // prob2['prob_id']=res.data.id;
  
  
  
      axios({
        method: 'put',
        url: link.url+'/api/v2/admin/problem/' + prob.id,
        data: prob2,
        headers: {
         'authorization': keys.authorization,
        }
      }).then(res=>{

        alert("SUccessfully Deleted")
      })



  

    })
  }

  searchText = (text) => {
    if(text === "waiting") {
      this.setState({ waitingQueueLoading: true });
      
      axios.get('https://dev.brainlytic.org/api/v2/admin/problem?limit=5000', {
        headers: {
          'authorization': keys.authorization,
        }
      }).then(res => {
        const filteredProblems = res.data.problems.filter(problem => 
          problem.draft === false && problem.is_approved === false
        );
        this.setState({
        
          mainAra: filteredProblems,
          
          count: filteredProblems.length,
          waitingQueueLoading: false,
          totPages:1
        });
        this.reShapeProblem(filteredProblems);
      }).catch(err => {
        this.setState({ waitingQueueLoading: false });
      });
    }
  
  }

  getLang = (e) => {
    this.setState({
      f: []
    })
    if (e.target.checked) {


      this.setState({ lang: 'bn' });

    }
    else {


      this.setState({ lang: 'en' });
    }

  }
  submit = async(prob) => {

    // let temp2=prob2;
    // let prob = await this.reShapeProblem(temp2);
    let temp = {};
    prob["serial"] = parseInt(this.state.serial);

    prob["series_id"] = parseInt(this.state.series_id);
    prob["is_live"] = true;
    prob["is_pending"] = false;
    prob['timestp']= Date.now()
    prob['t_threshold_minute']=5
    temp["problem"] = prob;

    console.log(temp)
console.log(prob)
    axios({
      method: 'post',
      url: link.url+'/admin/addProblem',
      data: temp,
      headers: {
        'authorization': keys.authorization,
      }
    }).then(res => {
console.log(res.data)
   
let prob2 = this.state.mainAra.find(problem => problem.id === prob.id);  
 console.log(prob2)

    prob2["serial"] = this.state.serial;

    prob2["series_id"] = this.state.series_id;
    prob2["is_live"] = true;
    prob2["is_approved"] = true;
    prob2["is_pending"] = false;
    prob2['prob_id']=res.data.id;



    axios({
      method: 'put',
      url: link.url+'/api/v2/admin/problem/' + prob.id,
      data: prob2,
      headers: {
       'authorization': keys.authorization,
      }
    }).then(res => {

      alert("problem successfully edited");
      // firebase.firestore().collection("problem").doc(id).update({
      //   isApproved: true,
      //   series_id: this.state.series_id,
      //   serial: this.state.serial,
      //   prob_id: res.data.id,
      //   isPending: false,
      //   isLive: true,
      //   isPremium: false,
      //   islive: true

      // })
    }).catch(e => console.log(e))
  })
  }



  render() {
    return (
      <div className="dashboard-container">
        <Navbar fun={this.searchText} />
        <div className="dashboard-content">
          <div className="action-panel">
            <div className="main-actions">
              <button
                className="action-button info"
                onClick={()=>this.click(1,null)}
                disabled={this.state.allLoading}
              >
                {this.state.allLoading ? (
                  <><i className="fas fa-spinner fa-spin"></i> Loading Problems...</>
                ) : (
                  <><i className="fas fa-sync"></i> Load Problems</>
                )}
              </button>
              
              <button
                className="action-button primary"
                onClick={this.bnclick}
                disabled={this.state.bnLoading}
              >
                {this.state.bnLoading ? (
                  <><i className="fas fa-spinner fa-spin"></i> Loading Bangla...</>
                ) : (
                  <><i className="fas fa-language"></i> Load All Bangla Problems</>
                )}
              </button>

              <button
                className="action-button secondary"
                onClick={this.UnMappedClick}
                disabled={this.state.UnMappedLoading}
              >
                {this.state.UnMappedLoading ? (
                  <><i className="fas fa-spinner fa-spin"></i> Loading Unmapped...</>
                ) : (
                  <><i className="fas fa-unlink"></i> Load All Unmapped Problems</>
                )}
              </button>
            </div>
            
            <div className="queue-indicator">
              <button 
                onClick={() => this.searchText("waiting")} 
                className="waiting-queue-btn"
                disabled={this.state.waitingQueueLoading}
                style={{
                  padding: '8px 16px',
                  borderRadius: '4px',
                  border: '1px solid #e0e0e0',
                  backgroundColor: '#fff',
                  color: '#666',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  fontSize: '0.9em',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                  ':hover': {
                    backgroundColor: '#f5f5f5',
                    borderColor: '#ccc'
                  }
                }}
              >
                {this.state.waitingQueueLoading ? (
                  <>
                    <i className="fas fa-spinner fa-spin"></i>
                    <span>Loading Queue...</span>
                  </>
                ) : (
                  <>
                    <i className="fas fa-clock" style={{color: '#ff9800'}}></i>
                    <span>Waiting Queue ({this.state.count})</span>
                  </>
                )}
              </button>
            </div>
          </div>

          <div className="filter-section">
            <div className="filter-group">
              <div className="filter-block">
                <label className="filter-label">Filter by Difficulty</label>
                <div className="filter-input-container">
                  <i className="fas fa-layer-group filter-icon"></i>
                  <select 
                    className="filter-input"
                    value={this.state.diff}
                    onChange={(e) => this.filterBydifficulty(e)}
                  >
                    <option value="All">All Difficulties</option>
                    <option value="easy">Easy</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                  </select>
                </div>
              </div>
              
              <div className="filter-block">
                <label className="filter-label">Filter by Author</label>
                <div className="filter-input-container">
                  <i className="fas fa-user filter-icon"></i>
                  <select 
                    className="filter-input"
                    value={this.state.setter}
                    onChange={(e) => {
                      this.click(2,e.target.value)
                      this.setState({ 
                      setter: e.target.value,
                      start: 0,
                      end: this.state.f.length 
                    })
                  
                    }}
                  >
                    <option value="">All Authors</option>
                    {this.state.setters
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(setter => (
                        <option key={setter.id} value={setter.email}>
                          {setter.name}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="problems-container">
            <div className="problems-grid">
              {this.state.f.length > 0 && 
                this.state.f.map((prob) => (
                  <div key={prob.id} className="problem-card" id={prob.id}>
                    <div className="card-image-wrapper">
                      <img src={prob.logo} alt={prob.title} className="problem-image" />
                      <div className="card-overlay">
                        <span className={`difficulty-badge ${prob.difficulty}`}>
                          {prob.difficulty}
                        </span>
                        {(!prob['is_live']|| prob['is_pending']) && <span className="status-badge pending">Pending</span>}
                      </div>
                    </div>
                    
                    <div className="card-content">
                      <div className="card-header">
                        <h3 className="card-title">{prob.title}</h3>
                        <small className="problem-id">ID: {prob.id}</small>
                      </div>
                      
                      <div className="card-meta" style={{
  padding: '12px 0',
  borderTop: '1px solid #eee',
  borderBottom: '1px solid #eee',
  margin: '10px 0'
}}>
  <span className="setter-name" style={{
    display: 'block',
    marginBottom: '8px',
    fontSize: '13px',
    color: '#555'
  }}>
    <i className="fas fa-user" style={{marginRight: '6px', color: '#666'}}></i>
    {prob['author_mail']}
  </span>
  
  <div className="timestamp-container" style={{
    display: 'flex',
    gap: '16px',
    fontSize: '0.8em',
    color: '#777'
  }}>
    <span className="created-time" style={{
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      fontSize:'11px'
    }}>
      <i className="fas fa-plus-circle" style={{color: '#4caf50'}}></i>
      Created {this.formatTimestamp(prob.created_at)}
    </span>
    
    <span className="updated-time" style={{
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
       fontSize:'11px'
    }}>
      <i className="fas fa-sync" style={{color: '#2196f3'}}></i>
      Updated {this.formatTimestamp(prob.updated_at)}
    </span>
  </div>
</div>

                      <div className="card-actions">
                        <div className="primary-actions">
                          <button
                            className="card-btn info"
                            data-toggle="collapse"
                            data-target={`#collapse${prob.id}`}
                          >
                            <i className="fas fa-info-circle"></i> Details
                          </button>
{/* 
                          {prob.translated !== true && prob.translated !== false ? ( */}
                            {/* <button
                              className="card-btn edit"
                              onClick={() => this.setState({currentProblem: prob})}
                              data-toggle="modal"
                              data-target="#editProblem"
                            >
                              <i className="fas fa-edit"></i>
                            </button> */}
                          {/* ) : ( */}
                            <Link 
                              to={{
                                pathname: `/problem/${prob.id}`,
                                myProps: { problem: prob,mainAra:this.state.mainAra }
                              }}
                              className="card-btn edit"
                            >
                              <i className="fas fa-edit"></i>
                            </Link>
                          {/* )} */}

                          {/* {!prob['is_pending'] && (
                            <button
                              className="card-btn view"
                              onClick={() => this.go(prob)}
                            >
                              <i className="fas fa-external-link-alt"></i> View
                            </button>
                          )} */}
                        </div>

                        <div className="secondary-actions">
                          {prob.series_id ? (
                            <button
                              className="card-btn delete"
                              onClick={() => this.delete(prob.problem_id, prob)}
                            >
                              <i className="fas fa-trash"></i> Delete
                            </button>
                          ) : (
                            <button
                              className="card-btn approve"
                              data-toggle="collapse"
                              data-target={`#collapse2${prob.id}`}
                            >
                              <i className="fas fa-check"></i> Approve
                            </button>
                          )}

                          
                            <button
                              className="card-btn map"
                              data-toggle="collapse"
                              data-target={`#collapse3${prob.id}`}
                            >
                              <i className="fas fa-link"></i> Map
                            </button>
                        
                        </div>
                      </div>

                      {/* Collapse panels */}
                      <div class="collapse" id={'collapse' + prob.id}>
                  <div class="card card-body">
                    <h6>AnsType :{prob.answer_type}</h6>
                    <h6>Setter : {prob.author_mail}</h6>
                    <h6>Category: {prob.category} </h6>
                    <h6>Grade: {prob.grade} </h6>
                    <h6>InteractiveType : {prob.interactive_type}</h6>
                    <h6>Approve Status : {prob.is_approved ? 'Approved' : 'Not Approved'}</h6>
                    <h6>Live Status: {prob.is_live ? 'Live' : 'Not Live'}</h6>
                    <h6>Premium Status : {prob.is_premium ? 'Premium' : 'Free'}</h6>
                    <h6><b>Series_id</b> : {prob.series_id}</h6>
                    {/* <h6><b>Topic_name </b> : {prob.topic_name}</h6> */}
                    {/* <h6><b>Doc_Id </b> : {prob.doc_id}</h6> */}
                    {/* <h6><b>Topic_level </b>: {prob.level_id-5}</h6> */}
                    
                   
                    {
                      prob.serial ? <h5>Serial:{prob.serial}</h5>
                        : null}
                    

                    <h6>Mapped ProblemId {prob.associated_problem_id}</h6>
                  </div>
                </div>
                <div class="collapse" id={'collapse2' + prob.id}>
                  <div class="card card-body">
                    <input placeholder="Enter series id" type="text" class="form-control" name="series_id" onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} />
                    <input placeholder="Enter serial " type="text" class="form-control my-3 py-3" name="serial" onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} />

                    <button className="btn btn-primary py-3 my-3" onClick={() => { this.submit(prob) }}>Submit</button>


                  </div>
                </div>
                <div class="collapse" id={'collapse3' + prob.id}>
                  <div class="card card-body">
                    <input onChange={(e) => this.setState({ mapped_id: e.target.value })} placeholder="Enter mapped problem id" type="text" class="form-control" name="mappedProblemId" />
                    <button onClick={(e) => this.editMappedProblem(prob)} className="btn btn-primary py-3 my-3" >Submit</button>

                  </div>
                </div>


                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          {/* Pagination section */}
          <div className="pagination-container" style={{
            padding: '30px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px'
          }}>
            <ul className="pagination" style={{
              display: 'flex',
              listStyle: 'none',
              gap: '8px',
              alignItems: 'center'
            }}>
              <li className={`page-item ${this.state.curPage === 1 ? 'disabled' : ''}`}>
                <button 
                  className="page-link"
                  onClick={() => this.setState({curPage: this.state.curPage - 1}, () => this.click(1,null))}
                  disabled={this.state.curPage === 1}
                  style={{
                    padding: '8px 16px',
                    border: '1px solid #e0e0e0',
                    borderRadius: '4px',
                    backgroundColor: this.state.curPage === 1 ? '#f5f5f5' : '#fff',
                    color: this.state.curPage === 1 ? '#bdbdbd' : '#2196f3',
                    cursor: this.state.curPage === 1 ? 'not-allowed' : 'pointer',
                    transition: 'all 0.3s ease',
                    fontWeight: '500'
                  }}
                >
                  <i className="fas fa-chevron-left"></i>
                </button>
              </li>

              {Array.from({ length: this.state.totPages }, (_, i) => (
                <li key={i} className={`page-item ${this.state.curPage === i + 1 ? 'active' : ''}`}>
                  <button
                    className="page-link"
                    onClick={() => this.setState({curPage: i + 1}, () => this.click(1,null))}
                    style={{
                      padding: '8px 16px',
                      border: '1px solid #e0e0e0',
                      borderRadius: '4px',
                      backgroundColor: this.state.curPage === i + 1 ? '#2196f3' : '#fff',
                      color: this.state.curPage === i + 1 ? '#fff' : '#2196f3',
                      cursor: 'pointer',
                      transition: 'all 0.3s ease',
                      fontWeight: '500',
                      minWidth: '40px',
                      textAlign: 'center'
                    }}
                  >
                    {i + 1}
                  </button>
                </li>
              ))}

              <li className={`page-item ${this.state.curPage === this.state.totPages ? 'disabled' : ''}`}>
                <button 
                  className="page-link"
                  onClick={() => this.setState({curPage: this.state.curPage + 1}, () => this.click(1,null))}
                  disabled={this.state.curPage === this.state.totPages}
                  style={{
                    padding: '8px 16px',
                    border: '1px solid #e0e0e0',
                    borderRadius: '4px',
                    backgroundColor: this.state.curPage === this.state.totPages ? '#f5f5f5' : '#fff',
                    color: this.state.curPage === this.state.totPages ? '#bdbdbd' : '#2196f3',
                    cursor: this.state.curPage === this.state.totPages ? 'not-allowed' : 'pointer',
                    transition: 'all 0.3s ease',
                    fontWeight: '500'
                  }}
                >
                  <i className="fas fa-chevron-right"></i>
                </button>
              </li>
            </ul>
          </div>

          {/* Problem Edit Modal */}
          {this.state.currentProblem && 
            (this.state.currentProblem.translated !== true && 
             this.state.currentProblem.translated !== false) && (
            <Problem 
              problem={this.state.currentProblem} 
              setCurrentProbToNull={this.setCurrentProbToNull} 
            />
          )}
        </div>
      </div>
    );
  }
}


export default (Allproblems);