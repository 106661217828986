import React, { useState } from 'react';
import Navbar from './Navbar';
import axios from 'axios';
import { keys } from '../keys';
import { link } from '../base_url';
const docIdToEmailMap = {
    "0RMFi9mrPNe7mol2JwcZAf40F3n2": "mehrab.haque.0001@gmail.com",
    "1LKqXHGSJfN6Jtn96BmgqXtjsq82": "ridhwan3ash@gmail.com",
    "3bib1lRMVAQMs5SAof3FlnYixfR2": "amsrumi@gmail.com",
    "6BNnWY2X9iW0sRmUhel1eGOGvOK2": "alfahadarc@gmail.com",
    "7rPIbCHPZPU4JWJboVlws7AiTbQ2": "dj2001dhaka@gmail.com",
    "8Xq06Cpjf6bingRbcoqRnNyTPdg1": "anupbhowmik.1998@gmail.com",
    "96L7jidBxJW8vTTy6WrArU2qpd63": "aditto991@gmail.com",
    "A3GXHDPr7BUTuaZ2yMhDlZUfeV12": "tuluashfaq803344@gmail.com",
    "CH7OD3dwpnUdZSFvsTAvafS1toF2": "1805001@ugrad.cse.buet.ac.bd",
    "CjGWpu5DBzdl49OJqwMplAiuhn22": "asifurbuet98@gmail.com",
    "D6aNBmlTD9SAgtQ2y7vXwWKYfqE3": "mahirlabibdihan@gmail.com",
    "Duq6BDGCyAa5rLnIXML1yOjdOQp1": "farhantanvir9019@gmail.com",
    "EIsXEClX3bWahwUCFBJJWNlbo832": "admin@brainlytic.com",
    "EdgytjFmwPSV9R28npC8Dqaqqm52": "muttakinbgd@gmail.com",
    "FgldhOxWc5Yp0U7yt1pH2JOO1672": "arafatrahman219@gmail.com",
    "G2fkZ6wngJQ4cVFRRaIEGxEHs3C3": "zawadx9193@gmail.com",
    "GOKkDYX10eZJtpQ9mYgAlBTfJOM2": "1805019@ugrad.cse.buet.ac.bd",
    "Hn4jI4W0tFW6zFuXl94nd7IbieI3": "mswarna28@gmail.com",
    "I97my9P8SJhfh4WrtV4CTc8xNo92": "rabibjahin2000@gmail.com",
    "IAJxbfU18PcqlAmg0Yl2Ja24Aeh2": "imrosezahin@gmail.com",
    "J5b9qq0hdkT6Hf1AsKiHapzkVcK2": "azmainawsaf5431@gmail.com",
    "JuojUXzaPPaW7l55pSgwHSmceIf1": "1805003@ugrad.cse.buet.ac.bd",
    "LWESDhHhcCWOkDyvmYNchf4Pr9b2": "mahirshahriarbd@gmail.com",
    "MjPTgfGBThPqHU9GsYsjom0vdUb2": "macmufti13@gmail.com",
    "Nep8qf10TnNBCHbMFBTQmqbPxPH3": "tahjibkhan31@gmail.com",
    "Nl5iSyYIeRbhf5OegBweQbw6k703": "wasimur211@gmail.com",
    "PmpkgO9x1mZrxd48xn67kEWL8ir2": "ibtida01.ahmed@gmail.com",
    "QIYXtD8ZXXQWWa6fWrXxuurGovY2": "tamzeedmahfuz2003@gmail.com",
    "RhBBTlcbaHPXWWiBxLoXjYwKt0m2": "hoque.anindya2@gmail.com",
    "U9OsXPlFKUaH98jUc7qNShqaFIo1": "azmanamin14@gmail.com",
    "VEyUb7xePYQZBwtwPMiZiQJdkoT2": "rdey4901@gmail.com",
    "VoTf81DreZTqGwSXurQ7g8DBnV43": "princezarzees5075@gmail.com",
    "WSSTekd8L8PtJ9ipThsCQ86Q3fs2": "zohorajasmin@gmail.com",
    "ZZdM6tzRd2Mwtf1WfFQwjD5KPJf1": "zannatulfzisha6164@gmail.com",
    "a3iUeMk2GKSwTyePZa0XS077Pe33": "awsafsami@gmail.com",
    "bWqV1RhStaNRdpJF2nhPlcqUwIt2": "buet.edu.project.1@gmail.com",
    "bqB7NqnV8zd3nrQnYnLgZPaPuGZ2": "azwadtanjeem@gmail.com",
    "chn4mCsrRmb1P6QXHCbrEeEpDAl2": "rownokratul11@gmail.com",
    "gfSCSnxIs1dKS7s81wyDVbY1jl82": "supriopaul461@gmail.com",
    "gzbf4HV4wLZNtbE3vAaLE6VFvOZ2": "masumbuetcse18@gmail.com",
    "hhT5nWhndYbuSPOEZyEb17cRZR92": "abonykamal@gmail.com",
    "hxhlwyVuqUgiz4PBZtE2cAWgVvZ2": "samuritaimir520@gmail.com",
    "kCXDxXQETpc7FaCizjfbS631VCu1": "maruf.howlader2016@gmail.com",
    "kMaoaNQ6EgY1FfrXSnC9oq8Ulkn1": "tamimehsan99@gmail.com",
    "lX1b4hntMXNd3yMohvWsiYjnFQ42": "othiya2003@gmail.com",
    "mBZTh8cox5R35pOJLtOPhCwFjVD3": "anindya.iqbal@gmail.com",
    "md3Ir9zIdbVYUFl7sQzuz16zuwJ3": "rafarkabir@gmail.com",
    "mrKHTJwh6cc59iBuaPHnLS7s0Er1": "zannatulzisha@gmail.com",
    "nm5V2TUSw0UsxmcHWPH1BlEPxP02": "sdipit099@gmail.com",
    "pOCjsHr3TVOQ05aaNmhMeu8UYS62": "tahjibkhan11@gmail.com",
    "pUeN9bd09cRVN5dX91vT26wHcf13": "mmprapty@gmail.com",
    "pkkI7mEQLzOi9uHENy9ncG8yNkX2": "raihanrsd98@gmail.com",
    "qvQFz1bkoHOQbf29uwCBojZjDxF3": "sairayeasminsa@gmail.com",
    "sDCgfAyDqYhCWzPhpyCXj8aU0b63": "saadbinquddus@gmail.com",
    "sFFNCJsG9PhmNg21hWdxzTn6RRf2": "tabassumsuchi6025@gmail.com",
    "saQZhJcModWeIxd8Rx9RSjydbnI3": "buet.contest.2@gmail.com",
    "st1KFPBwmUNxPq6gtlcevCF7RqA3": "mashroor184@gmail.com",
    "vbOZSH9ZP5MYx5X6jDOOG7ga2zX2": "taimir.eee.buet03@gmail.com",
    "vh5a8EkDHUTky1xcLNZDAwEZ1ig1": "sabit.jehadul.karim@gmail.com",
    "wKwZEsIHsZd9cPrSLSptXB6W8w22": "1805047@ugrad.cse.buet.ac.bd",
    "wRtTZXYAcUNEh25saOQNWVPDQw72": "nnaher123@gmail.com",
    "ysuL9ScpQObMR6RqOGyFpl9ZcMq2": "mesbahuddin1205@gmail.com",
    "yuP0CWs1rkUpcwy66iXjVDZFlTv2": "tahjibkhan235@gmail.com",
    "zCx893uuGHa93t6Q2ZU1pVJqWgw2": "roy396139@gmail.com",
    "zOF75vleZrgkivKiSSWj9HJcHmW2": "ramisa.fiza@gmail.com",
    "zoK6UfJx6JSTlysUPnxG4ztfs6k2": "itzhishamhere@gmail.com"
  };
  

const ProblemToSetter = () => {
  const [seriesId, setSeriesId] = useState('');
  const [serial, setSerial] = useState('');
  const [problem, setProblem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setProblem(null);

    try {
      const response = await axios.get(
        `${link.url}/problemBySerial/${seriesId}/${serial}`,
        {
          headers: {
            'authorization': keys.authorization
          }
        }
      );

      if (response.data) {
        setProblem(response.data);
      } else {
        setError('Problem not found');
      }
    } catch (err) {
      setError('Failed to fetch problem details');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getAuthorEmail = (authorIdentifier) => {
    if (!authorIdentifier) return 'Unknown';
    
    // Check if the identifier contains an @ symbol (email)
    if (authorIdentifier.includes('@')) {
      return authorIdentifier;
    }
    
    // If not an email, try to find in the docIdToEmailMap
    return docIdToEmailMap[authorIdentifier] || 'Email not found';
  };

  return (
    <div className="dashboard-container">
      <Navbar />
      <div className="container mt-5">
        <div className="card shadow-sm">
          <div className="card-header bg-primary text-white">
            <h4 className="mb-0">Problem Finder</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSearch} className="mb-4">
              <div className="row g-3">
                <div className="col-md-5">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="seriesId"
                      placeholder="Enter Series ID"
                      value={seriesId}
                      onChange={(e) => setSeriesId(e.target.value)}
                      required
                    />
                    <label htmlFor="seriesId">Series ID</label>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-floating">
                    <input
                      type="number"
                      className="form-control"
                      id="serial"
                      placeholder="Enter Serial Number"
                      value={serial}
                      onChange={(e) => setSerial(e.target.value)}
                      required
                    />
                    <label htmlFor="serial">Serial Number</label>
                  </div>
                </div>
                <div className="col-md-2 d-flex align-items-center">
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={loading}
                    style={{marginTop:"-30px"}}
                  >
                    {loading ? (
                      <><i className="fas fa-spinner fa-spin"></i> Searching...</>
                    ) : (
                      <><i className="fas fa-search"></i> Search</>
                    )}
                  </button>
                </div>
              </div>
            </form>

            {error && (
              <div className="alert alert-danger" role="alert">
                <i className="fas fa-exclamation-circle me-2"></i>
                {error}
              </div>
            )}

            {problem && (
              <div className="result-card">
                <div className="card border-0 shadow-sm">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-4">
                      <div className="problem-logo me-4">
                        <img 
                          src={problem.logo || 'https://via.placeholder.com/100'} 
                          alt="Problem Logo" 
                          style={{
                            width: '100px',
                            height: '100px',
                            objectFit: 'cover',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                          }}
                        />
                      </div>
                      <div>
                        <h5 className="card-title mb-2" style={{ fontSize: '1.4rem' }}>
                          {problem.title}
                        </h5>
                        <div className="d-flex align-items-center gap-2">
                          <span className="badge bg-primary">
                            ID: {problem.problem_id}
                          </span>
                          <span className="badge bg-secondary">
                            Series: {problem.series_id}
                          </span>
                          <span className="badge bg-info">
                            Serial: {problem.serial}
                          </span>
                        </div>
                      </div>
                    </div>
                    
                    <div className="problem-details">
                      <p className="mb-2">
                        <i className="fas fa-user text-primary me-2"></i>
                        <strong>Setter:</strong> {getAuthorEmail(problem.author_mail)}
                        {!problem.author_mail?.includes('@') && (
                          <small className="text-muted ms-2">
                            (Doc ID: {problem.author_mail})
                          </small>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProblemToSetter;
