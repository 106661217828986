import React, { Component } from "react"
import { useSelector } from "react-redux"
import { keys } from "../keys"
import axios from "axios"
import { fetchSerieses } from "../actions/seriesAction"
import { connect } from "react-redux"
import Series from "./Edit/Series"
import Navbar from "./Navbar"
import { Link } from "react-router-dom"
import { link } from "../base_url"
import "./allSerieses.css"

class AllSerieses extends Component {
  state = {
    name: "",
    description: "",
    serial: "",
    islive: "",
    nproblem: "",
    logo: "",
    topic_id: "",
    currentSeries: null,
    state: 0,
    serieses: [],
    topics:[],
    filterTitle: "",
    filterTopicId: "",
    filteredSeries: [],
    isSubmitting: false,
    isLoadingSeries: false,
    isUpdatingProblems: {},
    isAddingSeries: false
  };

  componentDidMount = async() => {


   
      // this.setState({ isLoadingTopics: true });
     
      // this.setState({ isLoadingTopics: false });
    }


  

  setSeries = async () => {
    this.setState({ isLoadingSeries: true });
try{
   const res=await axios.get(`https://dev.brainlytic.org/admin/getAllSeries`,{

      headers:{
        'authorization':'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWRtaW4iLCJ0eXBlIjo3LCJpYXQiOjE1MTYyMzkwMjJ9.xap-9yTG2FkwB7QXXxPx2kj4bypetY7cU51sMO6D-zQ',
      
      }
      
      })

 this.setState({
        // serieses: this.props.allserieses,
        filteredSeries: res.data,
        serieses:res.data
      });

    }catch(e){
      console.log(e);
      alert("Failed to load serieses");
    }

    this.setState({ isLoadingSeries: false });

    
  };

  handleFilter = () => {
    const { serieses, filterTitle, filterTopicId } = this.state;
    let filtered = [...serieses];

    if (filterTitle) {
      filtered = filtered.filter(series => 
        series.name.toLowerCase().includes(filterTitle.toLowerCase())
      );
    }

    if (filterTopicId) {
      filtered = filtered.filter(series => 
        series.topic_id.toString().includes(filterTopicId)
      );
    }
console.log(this.state.topics)
    this.setState({ filteredSeries: filtered });
  };

  handleFilterChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.handleFilter();
    });
  };

  change = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  setCurrentSeriesToNull = () => {
    this.setState({ currentSeries: null });
  };

  demo = (series) => {
    return (
      <div>
        <Series series={series} />
      </div>
    );
  };

  submitNProblem = async (id) => {
    this.setState(prev => ({ isUpdatingProblems: { ...prev.isUpdatingProblems, [id]: true } }));
    let temp = {
      series_id: id,
      nproblem: this.state.nproblem
    };
    try {
      await axios({
        method: "post",
        url: link.url + "admin/editSeriesProblemCount",
        data: temp,
        headers: {
          authorization: keys.authorization
        }
      });
      alert("Successfully edited nproblem");
    } catch (e) {
      console.log(e);
      alert("Failed to update problems");
    }
    this.setState(prev => ({ isUpdatingProblems: { ...prev.isUpdatingProblems, [id]: false } }));
  };

  submit = async () => {
    this.setState({ isAddingSeries: true });
    let temp = {};
    temp["series"] = this.state;

    try {
      await axios({
        method: "post",
        url: link.url + "admin/addSeries",
        data: temp,
        headers: {
          authorization: keys.authorization
        }
      });
      alert("Successfully created");
    } catch (e) {
      console.log(e);
      alert("Failed to create series");
    }
    this.setState({ isAddingSeries: false });
  };

  searchText = (text) => {
    let temp = [];

    this.props.allserieses.forEach((e) => {
      if (e.name.toLowerCase().includes(text.toLowerCase())) {
        temp.push(e);
      }

      this.setState({
        serieses: temp
      });
    });
  };

  render() {
    return (
      <div className="dashboard-container">
        <Navbar fun={this.searchText} />
        <div className="dashboard-content">
          <div className="action-panel">
            <div className="main-actions">
              <button
                className="action-button primary"
                data-toggle="modal"
                data-target="#exampleModalLong"
                disabled={this.state.isAddingSeries}
              >
                {this.state.isAddingSeries ? (
                  <><i className="fas fa-spinner fa-spin"></i> Adding...</>
                ) : (
                  <><i className="fas fa-plus-circle"></i> Add Series</>
                )}
              </button>
              <button
                className="action-button info"
                onClick={this.setSeries}
                disabled={this.state.isLoadingSeries}
              >
                {this.state.isLoadingSeries ? (
                  <span><i className="fas fa-spinner fa-spin"></i> Loading...</span>
                ) : (
                  <><i className="fas fa-sync"></i> Load Series</>
                )}
              </button>
            </div>
          </div>

          {/* Filter Section */}
          <div className="filter-section">
            <div className="filter-group">
              <div className="filter-block">
                <label className="filter-label">Filter by Title</label>
                <div className="filter-input-container">
                  <i className="fas fa-search filter-icon"></i>
                  <input
                    type="text"
                    className="filter-input"
                    placeholder="Search by series title..."
                    name="filterTitle"
                    value={this.state.filterTitle}
                    onChange={this.handleFilterChange}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="filter-block">
                <label className="filter-label">Filter by Topic ID</label>
                <div className="filter-input-container">
                  <i className="fas fa-hashtag filter-icon"></i>
                  <input
                    type="text"
                    className="filter-input"
                    placeholder="Enter topic ID..."
                    name="filterTopicId"
                    value={this.state.filterTopicId}
                    onChange={this.handleFilterChange}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="series-grid">
            {this.state.filteredSeries &&
              this.state.filteredSeries.map((series, i) => (
                <div key={series.series_id} className="series-card" id={series.series_id}>
                  <div className="series-card-header">
                    <img src={series.logo} className="series-logo" alt={series.name} />
                    <div className="series-title">
                      <h3>{series.name}</h3>
                    </div>
                  </div>
                  <div className="series-card-body">
                    <p className="series-description">{series.description}</p>
                    <div className="series-actions">
                      <button
                        className="series-btn details"
                        data-toggle="collapse"
                        data-target={'#collapse' + series.series_id}
                      >
                        <i className="fas fa-info-circle"></i> Details
                      </button>
                      <button
                        className="series-btn edit"
                        onClick={async () => {
                          await this.setState({ currentSeries: series });
                        }}
                        data-toggle="modal"
                        data-target="#editSeries"
                      >
                        <i className="fas fa-edit"></i> Edit
                      </button>
                      <button
                        className="series-btn problems"
                        data-toggle="collapse"
                        data-target={'#collapse2' + series.series_id}
                      >
                        <i className="fas fa-tasks"></i> Problems
                      </button>
                      <Link
                        to={{
                          pathname: `/addTutorial/${series.series_id}`,
                        }}
                        className="series-btn tutorial"
                      >
                        <i className="fas fa-book"></i> Tutorial
                      </Link>
                    </div>

                    <div
                      className="collapse series-details"
                      id={'collapse' + series.series_id}
                    >
                      <div className="details-grid">
                        <div className="detail-item">
                          <span className="detail-label">Problems:</span>
                          <span className="detail-value">{series.nproblem}</span>
                        </div>
                        <div className="detail-item">
                          <span className="detail-label">ID:</span>
                          <span className="detail-value">{series.series_id}</span>
                        </div>
                        <div className="detail-item">
                          <span className="detail-label">Serial:</span>
                          <span className="detail-value">{series.serial}</span>
                        </div>
                        <div className="detail-item">
                          <span className="detail-label">Topic ID:</span>
                          <span className="detail-value">{series.topic_id}</span>
                        </div>
                      </div>
                    </div>

                    <div className="collapse" id={'collapse2' + series.series_id}>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control modern-input editable-input"
                          placeholder="Enter number of problems"
                          onChange={(e) => this.setState({ nproblem: e.target.value })}
                          autoComplete="off"
                        />
                        <button
                          className="btn-submit"
                          onClick={() => this.submitNProblem(series.series_id)}
                          disabled={this.state.isUpdatingProblems[series.series_id]}
                        >
                          {this.state.isUpdatingProblems[series.series_id] ? (
                            <><i className="fas fa-spinner fa-spin"></i> Updating...</>
                          ) : (
                            <> Update Problems</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* Add Series Modal */}
          <div className="modal fade" id="exampleModalLong">
            <div className="modal-dialog">
              <div className="modal-content modern-modal">
                <div className="modal-header">
                  <h5 className="modal-title">Add Series</h5>
                  <button type="button" className="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control modern-input"
                      placeholder="Series Title"
                      name="name"
                      onChange={this.change}
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control modern-input"
                      placeholder="Description"
                      name="description"
                      onChange={this.change}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control modern-input"
                      placeholder="Topic ID"
                      name="topic_id"
                      onChange={this.change}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control modern-input"
                      placeholder="Logo URL"
                      name="logo"
                      onChange={this.change}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control modern-input"
                      placeholder="Is Live (1 or 0)"
                      name="islive"
                      onChange={this.change}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control modern-input"
                      placeholder="Number of Problems"
                      name="nproblem"
                      onChange={this.change}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control modern-input"
                      placeholder="Serial"
                      name="serial"
                      onChange={this.change}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn-submit"
                    onClick={this.submit}
                    disabled={this.state.isAddingSeries}
                  >
                    {this.state.isAddingSeries ? (
                      <><i className="fas fa-spinner fa-spin"></i> Creating...</>
                    ) : (
                      <><i className="fas fa-save"></i> Create Series</>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Edit Series Modal */}
          {this.state.currentSeries && (
            <Series series={this.state.currentSeries} setCurrentSeriesToNull={this.setCurrentSeriesToNull} />
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    serieses: async () => await fetchSerieses(dispatch)
  };
};

const mapStateToProps = (state) => {
  return {
    allserieses: state.seriesReducer
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllSerieses);