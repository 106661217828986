import React from "react"
import {useState} from "react"
import {useDispatch} from "react-redux"
import {searchText} from "../actions"
import {Link} from "react-router-dom"
import Allproblems from "./Allproblems-latest"
import firebase from "../firebase"

const Navbar = (props) => {
  const [search, setText] = useState("");
  const dispatch = useDispatch();

  const signout = () => {
    const auth = firebase.auth();
    auth.signOut()
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark" style={{
      width: '100%',
      padding: '0.5rem 1rem'
    }}>
      <div className="container-fluid">
        <a className="navbar-brand" href="/">Web Admin</a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{
          background: "#343a40",
          justifyContent: "space-between"
        }}>
          <ul className="navbar-nav mr-auto align-items-center">
            <li className="nav-item active">
              <a className="nav-link" href="/">Home</a>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="/allTopics">Topics</a>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="/allSerieses">Serieses</a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown">
                Problems
              </a>
              <div className="dropdown-menu">
                <a className="dropdown-item" href="/allProblems">All Problems</a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="/probStats">Stats</a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="/liveProbs">Live Problems</a>
              </div>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="/problem_to_setter">
              
                Find Problem
              </a>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="/allTests">Tests</a>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="/role">Manage Roles</a>
            </li>
          </ul>

          <div className="d-flex align-items-center">
            <form className="form-inline mr-3">
              <input
                className="form-control"
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => props.fun(e.target.value)}
              />
            </form>
            <a
              className="nav-link text-light"
              onClick={signout}
              style={{ cursor: 'pointer' }}
            >
              SignOut
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;