import React, { Component } from "react"
import Navbar from "./Navbar"
import { keys } from "../keys"
import axios from "axios"
import {link} from "../base_url"

class Role extends Component {
  state = {
    roles: [],
    authors: [],
    loading: false,
    authorsLoading: false,
    error: null,
    showAuthors: false,
    roleAssignLoading: false,
    selectedRoles: {},  // Store temporarily selected roles before confirmation
  }

  componentDidMount() {
    this.fetchRoles();
  }

  fetchRoles = () => {
    this.setState({ loading: true });
    axios.get('https://dev.brainlytic.org/api/v2/admin/management/roles', {
      headers: {
        'authorization': keys.authorization
      }
    })
    .then(res => {
      console.log('Fetched roles:', res.data);
      this.setState({
        roles: res.data || [],
        loading: false
      });
    })
    .catch(err => {
      console.error('Role fetch error:', err);
      this.setState({
        error: 'Failed to fetch roles',
        loading: false
      });
    });
  }

  loadAuthors = () => {
    this.setState({ authorsLoading: true, showAuthors: true });
    axios.get('https://dev.brainlytic.org/api/v2/setter/author?limit=1000', {
      headers: {
        'authorization': keys.authorization
      }
    })
    .then(res => {
      const sortedAuthors = res.data.authors.sort((a, b) => 
        a.name.localeCompare(b.name)
      );
      this.setState({
        authors: sortedAuthors,
        authorsLoading: false
      });
    })
    .catch(err => {
      this.setState({
        error: 'Failed to fetch authors',
        authorsLoading: false
      });
    });
  }

  handleRoleSelect = (authorId, roleId) => {
    this.setState(prevState => ({
      selectedRoles: {
        ...prevState.selectedRoles,
        [authorId]: roleId
      }
    }));
  }

  assignRole = (authorId) => {
    const role = this.state.selectedRoles[authorId];
    // console.log(roleId)
    if (!role) return;

    this.setState({ roleAssignLoading: true });
    
    axios({
      method: 'post',
      url: `${link.url}/api/v2/admin/management/roles/setter/assign`,
      headers: {
        'authorization': keys.authorization
      },
      data: {
       setterId: authorId,
        role: role
      }
    })
    .then(res => {
      const updatedAuthors = this.state.authors.map(author => {
        if (author.id === authorId) {
          return { ...author, role: role };
        }
        return author;
      });
      
      this.setState(prevState => ({ 
        authors: updatedAuthors,
        roleAssignLoading: false,
        selectedRoles: {
          ...prevState.selectedRoles,
          [authorId]: null
        }
      }));
      alert('Role assigned successfully!');
    })
    .catch(err => {
      alert('Failed to assign role');
      this.setState({ roleAssignLoading: false });
    });
  }

  getCurrentRoleName = (roleId) => {
    const role = this.state.roles.find(r => r.id === roleId);
    return role ? role.name : 'No role assigned';
  }

  render() {
    const { roles, authors, loading, error, authorsLoading, showAuthors } = this.state;

    return (
      <div className="dashboard-container">
        <Navbar />
        <div className="dashboard-content" style={{ padding: '20px' }}>
          <h2 className="page-title" style={{
            marginBottom: '20px',
            color: '#333',
            borderBottom: '2px solid #2196f3',
            paddingBottom: '10px'
          }}>
            Role Management
          </h2>

          <div className="action-buttons" style={{
            marginBottom: '20px',
            display: 'flex',
            gap: '10px'
          }}>
            <button 
              className="btn btn-primary"
              onClick={this.loadAuthors}
              disabled={authorsLoading}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                padding: '10px 20px',
                backgroundColor: '#2196f3',
                border: 'none',
                borderRadius: '4px',
                color: 'white',
                fontWeight: '500'
              }}
            >
              {authorsLoading ? (
                <><i className="fas fa-spinner fa-spin"></i> Loading Authors...</>
              ) : (
                <><i className="fas fa-users"></i> Load Authors List</>
              )}
            </button>
          </div>

          {showAuthors && (
            <div className="authors-section" style={{
              backgroundColor: '#fff',
              borderRadius: '8px',
              padding: '20px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
            }}>
              <h3 style={{
                color: '#333',
                marginBottom: '20px',
                display: 'flex',
                alignItems: 'center',
                gap: '10px'
              }}>
                <i className="fas fa-user-circle"></i>
                Authors ({authors.length})
              </h3>

              <div className="authors-grid" style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))', // Increased min width
                gap: '20px',
                padding: '20px 0'
              }}>
                {authors.map(author => (
                  <div key={author.id} className="author-card" style={{
                    padding: '24px',
                    borderRadius: '12px',
                    border: '1px solid #e0e0e0',
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
                    transition: 'all 0.3s ease',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px'
                  }}>
                    {/* Author info section */}
                    <div style={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      gap: '15px'
                    }}>
                      <div style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        backgroundColor: '#2196f3',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '1.4em',
                        fontWeight: '500',
                        flexShrink: 0
                      }}>
                        {author.name.charAt(0).toUpperCase()}
                      </div>
                      <div style={{
                        flex: 1,
                        minWidth: 0 // Prevents text overflow
                      }}>
                        <h4 style={{ 
                          margin: '0', 
                          color: '#333', 
                          fontSize: '1.1em',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>
                          {author.name}
                        </h4>
                        <p style={{ 
                          margin: '5px 0', 
                          color: '#666', 
                          fontSize: '0.9em',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}>
                          {author.email}
                        </p>
                        <span style={{
                          display: 'inline-block',
                          padding: '4px 12px',
                          borderRadius: '12px',
                          fontSize: '0.8em',
                          backgroundColor: author.is_active ? '#e8f5e9' : '#ffebee',
                          color: author.is_active ? '#2e7d32' : '#c62828'
                        }}>
                          {author.is_active ? 'Active' : 'Inactive'}
                        </span>
                      </div>
                    </div>

                    {/* Role selection section */}
                    <div style={{ 
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '12px'
                    }}>
                      {/* Current role display */}
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        color: '#666',
                        fontSize: '0.9em'
                      }}>
                        <i className="fas fa-user-tag" style={{ color: '#2196f3' }}></i>
                        <span>Current Role: </span>
                        <span style={{ 
                          fontWeight: '500',
                          color: author.role==='moderator'? '#2196f3' : '#666'
                        }}>
                          {author.role}
                        </span>
                      </div>

                      {/* Role selection dropdown */}
                      <div style={{
                        width: '80%',
                        position: 'relative'
                      }}>
                        <i className="fas fa-shield-alt" style={{
                          position: 'absolute',
                          left: '12px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          color: '#666',
                          zIndex: 1
                        }}></i>
                        <select
                          className="form-control"
                          value={this.state.selectedRoles[author.id] || ''}
                          onChange={(e) => this.handleRoleSelect(author.id, e.target.value)}
                          disabled={this.state.roleAssignLoading}
                          style={{
                            width: '100%',
                            padding: '8px 12px 8px 35px',
                            borderRadius: '6px',
                            border: '1px solid #e0e0e0',
                            backgroundColor: '#f5f5f5',
                            fontSize: '0.95em',
                            cursor: 'pointer',
                            appearance: 'none',
                            boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                          }}
                        >
                          <option value="">Select Role</option>
                          {this.state.roles?.map(role => (
                            <option key={role.role} value={role.role}>
                              {role.displayName || 'Unnamed Role'}
                            </option>
                          ))}
                        </select>
                        <i className="fas fa-chevron-down" style={{
                          position: 'absolute',
                          right: '12px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          color: '#666',
                          pointerEvents: 'none'
                        }}></i>
                      </div>

                      {/* Confirm button - appears only when a role is selected */}
                      {this.state.selectedRoles[author.id] && (
                        <button
                          onClick={() => this.assignRole(author.id)}
                          disabled={this.state.roleAssignLoading}
                          style={{
                            padding: '8px 24px',
                            borderRadius: '4px',
                            border: 'none',
                            backgroundColor: '#4caf50',
                            color: 'white',
                            fontSize: '0.9em',
                            fontWeight: '500',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            transition: 'all 0.3s ease',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            ':hover': {
                              backgroundColor: '#43a047'
                            }
                          }}
                        >
                          {this.state.roleAssignLoading ? (
                            <><i className="fas fa-spinner fa-spin"></i> Assigning...</>
                          ) : (
                            <><i className="fas fa-check"></i> Confirm Role Assignment</>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {loading && (
            <div className="loading-indicator" style={{
              textAlign: 'center',
              padding: '20px'
            }}>
              <i className="fas fa-spinner fa-spin" style={{ fontSize: '24px', color: '#2196f3' }}></i>
              <p>Loading roles...</p>
            </div>
          )}

          {error && (
            <div className="error-message" style={{
              color: '#f44336',
              padding: '10px',
              backgroundColor: '#ffebee',
              borderRadius: '4px',
              marginBottom: '20px'
            }}>
             
            </div>
          )}

          <div className="roles-grid" style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
            gap: '20px',
            padding: '20px 0'
          }}>
            
          </div>
        </div>
      </div>
    );
  }
}

export default Role;
