import React, { useState, useEffect } from "react"
import Navbar from "./Navbar";
import { keys } from "../keys"
import axios from "axios"
import { link } from "../base_url"
import "./tests.css" // Create this file for styles

const AllTests = () => {

    const [tests, setTests] = useState([]);
    const [lang, setLang] = useState("");
    const [level, setLevel] = useState(0);
    const [type, setType] = useState(false);
    const [name, setName] = useState("")
    const [loading, setLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    const save = () => {
        setSaveLoading(true);
        let data = { name: name, lang: lang, level_id: level, type: type }

        axios({
            method: 'post',
            url: link.url + '/api/tests',
            data: data,
            headers: {
                'authorization': keys.authorization,
            }
        }).then(res => {
            console.log(res.data)
            alert("Successfully added test ");
            setSaveLoading(false);
        }).catch(e => {
            console.log(e)
            setSaveLoading(false);
        })
    }

    const deleteTest = ({ name, test_id }) => {
        let isExecuted = window.confirm("Are you sure to delete the test " + name + " ?");
        if (isExecuted) {
            axios({
                method: 'delete',
                url: link.url + '/api/tests/' + test_id,
                headers: {
                    'authorization': keys.authorization,
                }
            }).then(res => { window.alert('Test successfully deleted') })
                .catch(e => { console.log(e) })
        }
    }

    const load = () => {
        setLoading(true);
        axios({
            method: 'get',
            url: link.url + '/api/tests',
            headers: {
                'authorization': keys.authorization,
            }
        }).then(res => {
            console.log(res.data);
            setTests(res.data.data);
            setLoading(false);
        }).catch(e => {
            console.log(e);
            setLoading(false);
        })
    }

    return (
        <div className="dashboard-container">
            <Navbar />
            <div className="dashboard-content">
                <div className="action-panel">
                    <div className="main-actions">
                        <button
                            type="button"
                            className="action-button primary"
                            data-toggle="modal"
                            data-target="#exampleModal"
                        >
                            <i className="fas fa-plus-circle"></i> Add Test
                        </button>

                        <button
                            onClick={load}
                            className="action-button info"
                            disabled={loading}
                        >
                            {loading ? (
                                <><i className="fas fa-spinner fa-spin"></i> Loading Tests...</>
                            ) : (
                                <><i className="fas fa-sync"></i> Refresh Tests</>
                            )}
                        </button>
                    </div>
                </div>

                <div className="tests-grid">
                    {tests && tests.map(test => (
                        <div key={test.test_id} className="test-card">
                            <div className="test-card-header">
                                <h3 className="test-title">{test.name}</h3>
                                <div className="test-badges">
                                    <span className={`level-badge ${test.level_id === 6 ? 'level-1' : test.level_id === 7 ? 'level-2' : 'level-3'}`}>
                                        Level {test.level_id === 6 ? "1" : test.level_id === 7 ? "2" : "3"}
                                    </span>
                                    <span className={`status-badge ${
                                        test.status === 0 ? 'not-live' :
                                            (test.OnlineTest && (test.OnlineTest.duration * 60 * 60 * 1000 + parseInt(test.OnlineTest.test_date_time)) < Date.now()) ? 'completed' :
                                                'live'
                                        }`}>
                                        {test.status === 0 ? 'Not Live' :
                                            (test.OnlineTest && (test.OnlineTest.duration * 60 * 60 * 1000 + parseInt(test.OnlineTest.test_date_time)) < Date.now()) ? 'Completed' :
                                                'Live'}
                                    </span>
                                </div>
                            </div>

                            <div className="test-card-actions">
                                <a
                                    href={`/editTest/${test.level_id}/${test.test_id}/${test.lang}`}
                                    className="card-btn edit"
                                >
                                    <i className="fas fa-edit"></i> Edit
                                </a>
                                <button
                                    onClick={() => deleteTest(test)}
                                    className="card-btn delete"
                                    disabled={test.deleteLoading}
                                >
                                    {test.deleteLoading ? (
                                        <><i className="fas fa-spinner fa-spin"></i> Deleting...</>
                                    ) : (
                                        <><i className="fas fa-trash"></i> Delete</>
                                    )}
                                </button>
                                {test.OnlineTest && Object.keys(test.OnlineTest).length > 0 && (
                                    <a
                                        href={`/getResult/${test.level_id}/${test.test_id}/${test.lang}/${test.name}`}
                                        className="card-btn results"
                                    >
                                        <i className="fas fa-chart-bar"></i> View Results
                                    </a>
                                )}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Add Test Modal */}
                <div className="modal fade" id="exampleModal">
                    <div className="modal-dialog">
                        <div className="modal-content modern-modal">
                            <div className="modal-header">
                                <h5 className="modal-title">Add New Test</h5>
                                <button type="button" className="close" data-dismiss="modal">
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>Test Name</label>
                                    <input
                                        type="text"
                                        className="form-control modern-input"
                                        placeholder="Enter test name"
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Language</label>
                                    <select
                                        className="form-control modern-select"
                                        value={lang}
                                        onChange={(e) => setLang(e.target.value)}
                                    >
                                        <option value="" disabled>Select Language</option>
                                        <option value="en">English</option>
                                        <option value="bn">Bangla</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label>Level</label>
                                    <select
                                        className="form-control modern-select"
                                        value={level}
                                        onChange={(e) => setLevel(e.target.value)}
                                    >
                                        <option value="" disabled>Select Level</option>
                                        <option value="6">Level 1</option>
                                        <option value="7">Level 2</option>
                                        <option value="8">Level 3</option>
                                        <option value="9">Level 4</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label>Type</label>
                                    <select
                                        className="form-control modern-select"
                                        value={type}
                                        onChange={(e) => setType(e.target.value)}
                                    >
                                        <option value="" disabled>Select Type</option>
                                        <option value="0">Online</option>
                                        <option value="1">Practice</option>
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn-cancel"
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={save}
                                    className="btn-submit"
                                    disabled={saveLoading}
                                >
                                    {saveLoading ? (
                                        <><i className="fas fa-spinner fa-spin"></i> Saving...</>
                                    ) : (
                                        <><i className="fas fa-save"></i> Save Test</>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllTests;